header.Header {
	background: white !important;
	height: 88px;
	top: 0px;
	color: #333333;
	display: none;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0px 1rem;
	box-sizing: border-box;
	font-size: 24px;

	&.visible {
		display: flex; }
	&.notvisible {
		display: none; }

	#Header-root {
		>div {
			display: flex;
			align-items: center;
			justify-content: space-between; }

		.Title {
			line-height: 26px; }

		.SubTitle {
			line-height: 20px;
			color: #999999;
			font-size: 18px; } }

	svg.svg-inline--fa {
		color: #666666;
		font-size: 28px; } }

header.SubHeader {
	background: #DDDDDD;
	height: 56px;
	width: 100vw;
	padding: 0;

	&.notvisible {
		background: none;
		border-bottom: 1px solid #A6B9C8;
		color: white;

		a {
			color: #A6B9C888 !important;

			&.selected {
				color: white !important; } }

		.title {
			color: #A6B9C888 !important; } }

	#SubHeader-root {
		width: 100vw;
		padding: 0px 1rem;
		height: 56px;
		line-height: 56px;

		>div {
			display: flex;
			justify-content: space-between;

			a {
				color: #999999;

				&.selected {
					color: black; } } } } }
