@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

html {
	overflow: hidden;
	height: 100%;
	position: fixed; }

body {
	overflow: auto;
	height: 100%;
	position: relative; }


#App {
	min-height: 100vh;
	background: #F0F5FA;
	transition: 1s background;

	&.dark {
		background: #1C2333; }

	.Layout {
		background: none; } }
* {
	font-family: 'Public Sans', sans-serif;
	font-weight: 300; }

ul {
	list-style-type: none;
	margin: 0;
	padding: 0; }

.ant-btn {}

.ant-btn.ant-btn-primary {
	background: #A6B9C8;
	border: none;
	color: white;
	box-shadow: none; }

.noselection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

.text-left {
	text-align: left; }

.mt-auto {
	margin-top: auto !important; }
.mr-auto {
	margin-right: auto !important; }
.ml-auto {
	margin-left: auto !important; }
.ml-1 {
	margin-left: 1rem !important; }
.ml-2 {
	margin-left: 2rem !important; }
.ml-3 {
	margin-left: 3rem !important; }
.ml-4 {
	margin-left: 4rem !important; }
.mr-1 {
	margin-right: 1rem !important; }
.mr-2 {
	margin-right: 2rem !important; }
.mr-3 {
	margin-right: 3rem !important; }
.mr-4 {
	margin-right: 4rem !important; }

.ant-popover {
	z-index: 20; }

.ant-tooltip {
	z-index: 10; }

#copyright {
	position: fixed {
    width: 450px;
    text-align: center; }
	bottom: 0px;
	color: rgba(255,255,255,0.4);
	font-size: 11px;
	left: calc( 50% - 225px );

	small {
		display: none; } }
