.Scenarios {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		font-size: 1.1rem;
		padding: 0 1rem;
		color: #2EA0DF;
		text-align: center; }

	h3 {
		font-size: 0.8rem;
		color: #2EA0DF;
		margin-bottom: 1.5rem;
		text-align: center; }

	.ant-tabs-tab {
		background: none !important;
		border-radius: 0px !important;

		.ant-tabs-tab-btn {
			color: white !important; } }


	.ant-tabs-tab.ant-tabs-tab-active {
		background: #A6B9C8 !important;
		border-color: #A6B9C8 !important;
		border-right-color: #A6B9C8 !important;
		border-top-color: #A6B9C8 !important;

		.ant-tabs-tab-btn {
			color: black !important; } } }
