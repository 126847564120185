

.ProjectItem {
	max-width: 400px;
	min-width: 300px;
	background: #363D4D;
	border-radius: 10px;
	color: white;
	padding: 1rem;
	height: 120px;
	line-height: 24px;
	font-size: 16px;
	position: relative;
	margin-bottom: 1em;

	.action {
		position: absolute;
		bottom: 12px;

		&.clone {
			right: 15px; } } }
