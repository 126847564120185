.Weight {
	display: flex;
	cursor: pointer;

	>div {
		height: 20px;
		width: 20px;
		border-radius: 1px;
		border: 1px solid white;
		color: #666;

		svg {
			visibility: hidden; }

		&.filled {
			background: white;
			background: white;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				visibility: visible; } } }


	>div+div {
		margin-left: 3px; }

	&.dark {
		>div {
			border: 1px solid #888888;
			background: #888888;
			&.filled {
				background: white; } } }

	&.disabled {
		>div.filled {
			background: #CCCCCC;
			border: 1px solid #CCCCCC; }
		>div {
			cursor: not-allowed; } }

	&.disabled.dark {
		>div {
			background: #555555;
			&.filled {
				background: #CCCCCC; } } } }
