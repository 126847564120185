.ProjectNavigation {
	display: flex;
	width: 100%;
	height: 55px;
	align-items: center;
	background: #1c2333;

	a {
		display: block;
		flex-shrink: 0;

		&.selected {
			color: #1C2333; }

		&.filled {
			border-color: #1C2333; }

		&.home {
			padding: 8px 0px 0px; } }

	.separator {
		width: 60px;
		height: 50px;
		margin: 0 15px 0 20px;
		flex-shrink: 0;
		flex-grow: 0;

		&:before {
			border-left: 1px solid white;
			position: absolute;
			transform: rotate(40deg);
			content: "";
			height: 72px;
			top: -8px;
			display: block;
			margin-left: 25px; } }

	.scenario {
		height: 56px;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		width: 100%;


		.navigation {
			display: flex;
			align-items: center;
			margin-left: -10px;

			.chevron {
				padding: 0.5rem;
				width: 35px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center; } }

		.tag {
			background: none;
			color: #A6B9C8;
			border: 1px solid #A6B9C844; } } }
