#Amphi {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 200px;
	height: 200px;
	margin-left: -100px;
	margin-top: -100px;
	transform: scale(0);
	//transition-property: transform
	//transition-duration: 1s

	.action {
		cursor: pointer;
		z-index: 101;
		margin-left: 100px;
		margin-top: 100px;
		width: 1px;
		height: 1px;
		position: absolute;
		font-size: 1.8em;
		display: flex;
		justify-content: center;
		align-items: center;

		.small {
			transform: scale(0.5);
			transform-origin: 50%; }

		.medium {
			transform: scale(0.70);
			transform-origin: 50%; } }

	.sliceWrapper {
		.slice {
			opacity: 0;
			transform-origin: 0 0;
			animation-name: radar-circle;
			animation-duration: 1s;
			animation-iteration-count: 1;
			animation-timing-function: cubic-bezier(.02,.99,.54,.94);
			animation-fill-mode: forwards;
			cursor: pointer; }

		&:hover .slice, &.selected .slice {
			fill: #A6B9C8; } }

	svg {
		z-index: 100; }

	&.visible {
		transform: scale(1); } }



