.Fishbone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		font-size: 1.1rem;
		padding: 0 1rem;
		color: #2EA0DF;
		text-align: center; }

	h3 {
		font-size: 0.8rem;
		color: #2EA0DF;
		margin-bottom: 1.5rem;
		text-align: center; }

	* {
		transform-duration: 1s;
		transform-property: cx cy x y x0 x1 y0 y1; }

	.add, .add * {
		cursor: pointer; }

	>svg {
		margin-top: 2em; } }
