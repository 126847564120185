.ActionButton {
	border-radius: 40px;
	background: #A6B9C8;
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 38px;
	color: white; }

.small {
	.ActionButton {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		font-size: 18px; } }

.xsmall {
	.ActionButton {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		font-size: 12px; } }
