.Project {
	padding: 1rem 1rem 1rem;
	padding-top: calc( 56px + 1rem );
	color: white;
	width: 100%; }

.expanded {
	.Project {
		padding-top: calc( 56px + 1rem ); }
	.ant-layout {
		padding-top: 0; } }
