.Navigator {
	width: calc( 100vw - 2rem );
	height: calc( 100vh - 88px - 56px - 2rem );
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.content {
		scrollbar-color: white #1C2333;
		scrollbar-width: thin; }

	&.linking {
		cursor: crosshair; }
	.Body {
		display: flex;
		flex-direction: row;
		height: calc( 100vh - 88px - 56px - 2rem );
		width: 100%;
		flex-shrink: 1;
		justify-content: center;

		.RadarWrapper {
			height: 100%; }


		#vectorialRadar {
			margin-right: 5px; } }

	#AddItem {
		position: absolute;
		left: calc( 300px + 1em );
		top: calc( 144px + 1em ); }

	#Goal {
		position: absolute;
		bottom: 10px;
		left: 10px; }

	.VulnerabilityScale {
		margin: 1rem 0; }

	.SideMenu {
		width: 0px;
		margin-top: -1rem;
		margin-bottom: -1rem;
		margin-right: -1rem;
		margin-left: 50px;
		height: calc( 100vh - 88px - 40px - 1rem );
		flex-shrink: 0;
		flex-grow: 0;
		color: #A6B9C8;

		input {
			border-width: 0px !important; }

		.SearchInput {
			background: none !important;
			border: 1px solid #A6B9C844 !important;
			color: white; }

		&.opened {
			max-width: 300px;
			width: 100%;
			border-left: 1px solid #A6B9C8; }

		>ul {
			list-style-type: none;
			padding: 0px;
			margin-left: -25px;
			position: absolute;
			margin-top: 120px;
			width: 0px;

			li {
				transform: rotate(-90deg);
				transform-origin: 0px;
				border: 1px solid #A6B9C8;
				height: 50px;
				width: 130px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				color: white;

				&+li {
					margin-top: 90px; }

				&.selected {
					background: #A6B9C8;
					color: #000000; }

				&.disabled {
					opacity: 0.25;
					cursor: initial; }

				&.toggle {
					border: none;
					background: #24CCB8;
					width: 40px;
					height: 40px;
					border-radius: 6px;
					margin-top: 29px;
					margin-left: -5px;

					svg path {
						fill: white; } } } }

		.content {
			height: 100%;
			overflow-y: scroll;
			overflow-x: hidden;
			text-align: center;
			padding: 0.5rem 1rem; }


		.IconPicker {
			>div {
				border: none !important;
				background: white;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 42px;
				>div {
					color: #000000 !important;
					font-size: 20px !important;
					padding: 0px !important; }
				&:nth-child(n+1) {
					border: none !important;
					input {
						border-radius: 0px !important;
						border: none !important;
						border-bottom: 1px solid #F0F0F0 !important; } } } }

		.Actions {
			display: flex;
			justify-content: flex-start;
			margin-top: auto;
			margin-bottom: 0.5rem;

			>div {
				margin-right: 1rem; } } }

	.SideMenuLeft {
		max-width: 300px;
		width: 100%;
		border-right: 1px solid #A6B9C811;
		margin-top: -1rem;
		margin-bottom: -1rem;
		margin-left: -1rem;
		margin-right: 50px;
		height: calc( 100vh - 88px - 40px - 1rem );
		flex-shrink: 0;
		flex-grow: 0;
		color: #A6B9C8; }

	.tag {
		display: inline-block;
		border-color: transparent;
		cursor: pointer;
		margin-bottom: 0.25rem;
		align-items: center;
		display: inline-flex;


		&.unselected {
			border: 1px solid #A6B9C844;
			background: none !important;
			color: #A6B9C8; }

		svg {
			margin-top: 4px !important; }

		.Weight {
			display: inline-flex;
			>div {
				height: 10px;
				width: 8px;
				border: 1px solid #A6B9C8;
				background: white;
				&.filled {
					background: #1C2333;
					border: 1px solid #1C2333; }

				&:first-child {
					margin-left: 5px; } }

			>div+div {
				margin-left: 1px; } }

		&.ImpactedIndicator, &.ImpactedConstraint {

			.Trend {
				border-radius: 8px;
				height: 8px;
				width: 8px;
				margin-right: 5px;
				background: white;

				&.red {
					background: #E70549; }
				&.orange {
					background: #93586A; }
				&.white {
					background: #FFFFFF; }
				&.blue {
					background: #4A6868; }
				&.green {
					background: #24CCB8; }

				&.v50 {
					background: linear-gradient(90deg,  #E70549 0%, #E70549 50%, #24CCB8 50%, #24CCB8 100%) !important; } } } }


	&.expanded {
		height: calc( 100vh - 56px - 2rem );

		.Body {
			height: calc( 100vh - 56px - 2rem ); }

		#AddItem {
			top: calc( 144px + 1em - 88px ); }

		.SideMenu, .SideMenuLeft {
			height: calc( 100vh - 40px - 1rem ); } } }


