.SynthesisGraphWrapper {
    position: relative;

    .SynthesisGraph {
        path {
            transition: 1.7s; } }

    .indicators, .stakefactors, .constraints {
        text-align: center;
        font-size: 11px;
        color: #FFFFFF66;
        position: absolute;
        width: 100%; }

    .indicators {
        top: 8px; }

    .stakefactors {
        top: 268px;
        text-align: left;
        margin-left: 0.5rem; }

    .constraints {
        top: 268px;
        text-align: right;
        padding-right: 0.5rem; }

    .score {
        fill: white;
        font-size: 50px;
        text-align: center;
        color: #FFFFFF66;
        position: absolute;
        right: 25px;
        top: 25px; } }
