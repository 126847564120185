.FishboneBranch {
	transition-property: transform;
	transition-duration: 1s;
	transition-timing-function: ease-out;

	line {
		stroke-dasharray: 1600;
		stroke-dashoffset: 500;
		animation-name: expand;
		animation-direction: reverse;
		animation-duration: 1s;
		animation-timing-function: cubic-bezier(.16,-0.01,0,1.06);
		animation-iteration-count: 1; }

	.vulnerability {
		fill: url(#redToGreen); }

	.vulnerabilityMarker {
		transition: 1s; }

	.FishbonePoint {
		cursor: pointer; }

	.title, .add, .points {
		animation-name: appear;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-iteration-count: 1; }

	.title {
		&.small tspan {
			font-size: 10px;
			width: 100px;
			height: 10px;
			fill: url('/images/weights/1.svg');
			color: white; }
		&.medium tspan {
			font-size: 16px; }
		&.big tspan {
			font-size: 25px; } }

	.add {
		animation-name: appear-add;
		animation-duration: 7s;
		animation-timing-function: linear;
		animation-iteration-count: 1; }

	.points {
		animation-name: appear-points;
		animation-duration: 3s;
		animation-timing-function: linear;
		animation-iteration-count: 1; } }

@keyframes expand {
	to {
		stroke-dashoffset: 1600; } }

@keyframes appear {
	0% {
		opacity: 0; }
	54% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes appear-points {
	0% {
		opacity: 0; }
	63% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes appear-add {
	0% {
		opacity: 0; }
	40% {
		opacity: 0; }
	100% {
		opacity: 1; } }
